<template>
  <div>
    <div class="table-responsive" style="overflow-x: auto;">
      <b-table
        striped
        hover
        :id="tableId"
        :fields="fields"
        :items="listDataProvider"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        style="min-width:1500px;"
      >
        <template v-slot:head(index)>
          <b-form-checkbox
            v-model="allchecked"
            @change="checkAll"
          ></b-form-checkbox>
        </template>
        <template v-slot:cell(index)="row">
          <b-form-checkbox
            @change="checkboxToggle(row.item)"
            v-model="row.item.checked"
          ></b-form-checkbox>
        </template>
        <template v-slot:empty="scope">
          <h4>{{ scope.emptyText }}</h4>
        </template>
        <template
          v-for="(tableName, selfName) in tableScopedSlots"
          v-slot:[tableName]="slotProps"
        >
          <slot :name="selfName" v-bind="slotProps" />
        </template>
      </b-table>
    </div>
    <b-row class="mt-4">
      <b-col md="2">
        <b-form-group
          label="Per page"
          label-cols-sm="3"
          label-cols-md="5"
          label-cols-lg="5"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="4" offset-md="6">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          align="fill"
          size="sm"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'dataTable',
  props: {
    tableId: String,
    tableFields: { type: Array, default: null }
  },
  data() {
    return {
      fields: this.tableFields,
      items: [],
      totalItems: 0,
      perPage: 10,
      currentPage: 1,
      pageOptions: [10, 20, 30, 50, 100, 200],
      sortBy: null,
      sortDesc: null,
      allchecked: false,
      selected: []
    };
  },
  components: {},
  methods: {
    // 点击全选按钮
    checkAll(checked) {
      this.items.forEach(element => {
        if (checked) {
          element.checked = true;
          this.selected = this.items;
        } else {
          element.checked = false;
          this.selected = [];
        }
      });
    },
    // 复选框点击选中与取消
    checkboxToggle(item) {
      item.checked = !item.checked;
      this.selected = this.items.filter(function(item) {
        return item.checked === true;
      });
    },

    getTableScopedSlots() {
      let tableScoped = {};
      let entries = Object.entries(this.$scopedSlots);
      for (const [k] of entries) {
        if (k.indexOf('table-') === 0) {
          tableScoped[k] = k.substr(6);
        }
      }
      return tableScoped;
    },
    listDataProvider(context, callback) {
      new Promise(resolve => {
        this.$emit('list-data-provider', context, ({ data }) => {
          resolve(data);
        });
      }).then(data => {
        let isChecked = { checked: false };
        data.items.forEach((element, index) => {
          Object.assign(element, isChecked, { id: index });
        });
        this.totalItems = data.total;
        this.items = data.items;
        callback(data.items);
      });
    }
  },
  computed: {
    tableScopedSlots() {
      return this.getTableScopedSlots();
    }
  },
  watch: {
    selected: {
      handler(newVal) {
        if (newVal.length == this.items.length) {
          this.allchecked = true;
        } else {
          this.allchecked = false;
        }
      },
      deep: true //深度
    }
  }
};
</script>

<style>
.table thead th {
  white-space: nowrap;
}
</style>
